import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";
import { ArrowSquareOut } from "phosphor-react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import {
  Tile,
  TileOutbound,
  TilesContainer,
  TitleContainer,
  Thumbnail,
} from "../components/foundations";

export default function Home({ data, pageContext }) {
  const url = "https://zholobak.com";

  // 👇 graphql
  const product = data.product;

  console.log(pageContext.outbounds);

  const scientificPublications = [
    {
      ogTitle: "Google Академия",
      category: "Science",
      ogUrl: "https://scholar.google.com.ua/citations?user=Js0G38kAAAAJ&hl=ru",
    },
    {
      ogTitle: "Scopus",
      category: "Science",
      ogUrl: "https://www.scopus.com/authid/detail.uri?authorId=6603236139",
    },
    {
      ogTitle: "Publons",
      category: "Science",
      ogUrl: "https://publons.com/researcher/1287352/nadiya-zholobak/",
    },
    {
      ogTitle: "ResearchGate",
      category: "Science",
      ogUrl: "https://www.researchgate.net/profile/Nadezhda-Zholobak",
    },
    {
      ogTitle: "ORCID",
      category: "Science",
      ogUrl: "https://orcid.org/0000-0003-2792-9787",
    },
  ];

  const items = data.product.items.concat(pageContext.outbounds, scientificPublications);

  console.log(items);


  const [category, setCategory] = React.useState(null);

  return (
    <>
      <Helmet htmlAttributes={{ lang: "ru" }}>
        <title>{product.title}</title>
        <meta name="description" content={product.description} />
        <meta name="author" content={product.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.description} />
        <meta property="og:image" content={url + "/og.png"} />
        <meta property="og:image:alt" content={product.title} />
      </Helmet>

      <StaticImage
        src="../images/icon.jpg"
        alt=""
        placeholder="blurred"
        layout="fixed"
        width={120}
        height={120}
        imgStyle={{ borderRadius: 24 }}
      />
      <header>
        <h1>{product.title}</h1>
        <p>{product.description}</p>
      </header>
      <main>
        <Categories>
          <Category
            onClick={() => setCategory(null)}
            isSelected={category === null}
          >
            Всё
          </Category>
          <Category
            onClick={() => setCategory("Interview")}
            isSelected={category === "Interview"}
          >
            Интервью
          </Category>
          <Category
            onClick={() => setCategory("Publicism")}
            isSelected={category === "Publicism"}
          >
            Публицистика
          </Category>
          <Category
            onClick={() => setCategory("Science")}
            isSelected={category === "Science"}
          >
            Научные публикации
          </Category>
        </Categories>
        <TilesContainer>
          {items
            .filter((i) => (category ? i.category === category : true))
            .sort(function (a, b) {
              // sort by date
              return new Date(b.date) - new Date(a.date);
            })
            .map((i, index) => (
              <React.Fragment key={index}>
                {i.slug ? (
                  <Tile to={`/${i.slug}/`}>
                    <TitleContainer>
                      {i.vimeoVideo?.posterFrame && (
                        <Thumbnail src={i.vimeoVideo.posterFrame.url} alt="" />
                      )}
                      <h3>{i.title}</h3>
                      <p>{i.description}</p>
                    </TitleContainer>
                    {i.date && (
                      <time pubdate="true" dateTime={i.date}>
                        {Intl.DateTimeFormat("ru", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(i.date))}
                      </time>
                    )}
                  </Tile>
                ) : (
                  <TileOutbound href={i.ogUrl} target="_blank" rel="noreferrer">
                    <TitleContainer>
                      <p>
                        <ArrowSquareOut />
                      </p>
                      <h2>{i.ogTitle}</h2>
                    </TitleContainer>
                    {i.ogDate && (
                      <time pubdate="true" dateTime={i.ogDate}>
                        {Intl.DateTimeFormat("ru", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }).format(new Date(i.ogDate))}
                      </time>
                    )}
                  </TileOutbound>
                )}
              </React.Fragment>
            ))}
        </TilesContainer>
      </main>
    </>
  );
}

const Details = styled.details`
  summary {
    cursor: pointer;
  }
  ul {
    padding: 0;
    margin: 20px 0 0 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      list-style-type: none;
      a {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
`;

const Categories = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  margin: 24px 0px;
  padding: 16px 0px;
  flex-wrap: wrap;
`;

const Category = styled.button`
  font-family: "Source Serif Pro", serif;
  font-size: 1em;
  border: 0;
  background-color: ${({ isSelected }) =>
    isSelected ? "var(--fg)" : "var(--bg-up)"};
  color: ${({ isSelected }) => (isSelected ? "var(--bg)" : "var(--fg)")};
  padding: 8px 16px;
  border-radius: 16px;
`;

export const query = graphql`
  query {
    product: graphCmsProduct(title: { eq: "Надежда Жолобак" }) {
      title
      description
      items {
        slug
        category
        title
        description
        date
        vimeoVideo {
          posterFrame {
            url
          }
        }
      }
    }
  }
`;
